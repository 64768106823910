import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Autocomplete,
  TextField,
  useMediaQuery,
  Container,
  Stack,
  Link,
  MenuItem,
  Fade,
  Grow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import theme from "../theme.jsx";
import countries from "../components/Countries.jsx";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useSearchParams } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import { Trans, useTranslation } from 'react-i18next';

const formStyles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#212121",
      color: "white",
    },
    "& .MuiInputLabel-root": {
      color: "#D8D8D8",
    },
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    paddingBottom: "32px",
    fontFamily: theme.typography.fontFamily,
  },
  flexRow: {
    display: 'flex',
    gap: { xs: '16px', md: '24px' },
    flexDirection: { xs: 'column', sm: 'row' },
    width: '100%'
  },
  sectionTitle: {
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: theme.typography.fontFamily,
  },
  submitButton: {
    mt: 1,
    py: 1.5,
    backgroundColor: "#FF8544",
    color: "black",
    borderRadius: "8px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600,
    width: "100%",
    maxWidth: { xs: '100%', md: '250px' },
    marginLeft: { xs: '0', md: 'auto' },
    "&:hover": {
      background: "#FF955C",
    },
  },
};

const InputWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
  fontFamily: theme.typography.fontFamily,
});

const Label = styled("div")({
  color: "#ffffff",
  fontSize: "16px",
  marginBottom: "6px",
  fontWeight: "500",
  fontFamily: theme.typography.fontFamily,
});

const StyledInput = styled("input")(({ error }) => ({
  padding: "16px",
  backgroundColor: "#212121",
  border: `1px solid ${error ? "#fd4c62" : "rgba(255, 255, 255, 0.1)"}`,
  borderRadius: "8px",
  color: "white",
  fontFamily: theme.typography.fontFamily,
  fontSize: "16px",
  "&::placeholder": {
    color: "#c5c5c5",
    fontWeight: "300",
  },
  "&:hover": {
    borderColor: error ? "#fd4c62" : "rgba(255, 255, 255, 0.3)",
  },
  "&:focus": {
    outline: "none",
    borderColor: error ? "#fd4c62" : "rgba(255, 255, 255, 0.3)",
  },
}));

const StyledTextArea = styled("textarea")(({ error }) => ({
  padding: "16px",
  backgroundColor: "#212121",
  fontFamily: theme.typography.fontFamily,
  border: `1px solid ${error ? "#fd4c62" : "rgba(255, 255, 255, 0.1)"}`,
  borderRadius: "8px",
  color: "white",
  fontSize: "16px",
  minHeight: "120px",
  resize: "vertical",
  "&::placeholder": {
    color: "#808080",
  },
  "&:focus": {
    outline: "none",
    borderColor: error ? "#fd4c62" : "rgba(255, 255, 255, 0.3)",
  },
  "&:hover": {
    borderColor: error ? "#fd4c62" : "rgba(255, 255, 255, 0.3)",
  },
}));

const FormField = ({
  label,
  placeholder,
  type = "text",
  multiline = false,
  value,
  onChange,
  error,
  name,
}) => (
  <InputWrapper>
    <Label>{label}</Label>
    {multiline ? (
      <StyledTextArea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        error={!!error}
      />
    ) : (
      <StyledInput
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        error={!!error}
      />
    )}
    {error && (
      <Typography
        sx={{
          color: "#fd4c62",
          fontSize: "12px",
        }}
      >
        {error}
      </Typography>
    )}
  </InputWrapper>
);

const ContactForm = ({ globalUrl, isCloud, formErrors, setFormErrors }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    companyName: "",
    message: "",
    category: searchParams.get('category') || "",
  });

  const [formStatus, setFormStatus] = useState({
    message: "",
    isSubmitting: false,
  });

  const [showCalendly, setShowCalendly] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);

  const categoryMap = {
    book_a_demo: "Book a Demo",
    talk_to_sales: "Talk to Sales",
    contact: "Contact",
    partnership: "Partnership",
    support: "Support",
    technical_question: "Technical Question",
    security_consultation: "Security Consultation",
    cloud_enterprise_plan: "Cloud Enterprise Plan",
    onprem_enterprise_plan: "On-Premise Enterprise Plan",
  };

  const handleInputChange = (field) => (event) => {
    // Clear error for the changed field
    setFormErrors((prev) => ({
      ...prev,
      [field]: undefined,
    }));

    // Update form data
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleCategoryChange = (event, newValue) => {
    setFormErrors((prev) => ({
      ...prev,
      category: undefined,
    }));
    setFormData((prev) => ({
      ...prev,
      category: newValue?.key || "",
    }));
    
    // Update URL
    if (newValue?.key) {
      searchParams.set('category', newValue.key);
    } else {
      searchParams.delete('category');
    }
    setSearchParams(searchParams);
  };

  const validateForm = () => {
    const errors = {};

    // Define validation rules matching backend
    const fieldValidations = [
      {
        value: formData.firstName,
        name: "firstName",
        label: "First name",
        minLen: 2,
        maxLen: 100,
      },
      {
        value: formData.lastName,
        name: "lastName",
        label: "Last name",
        minLen: 2,
        maxLen: 100,
      },
      {
        value: formData.companyName,
        name: "companyName",
        label: "Company name",
        minLen: 2,
        maxLen: 100,
      },
    ];

    // Validate all fields with length requirements
    fieldValidations.forEach(({ value, name, label, minLen, maxLen }) => {
      if (!value || value.length < minLen) {
        errors[name] = `${label} is too short (minimum ${minLen} characters)`;
      } else if (value.length > maxLen) {
        errors[name] = `${label} is too long (maximum ${maxLen} characters)`;
      }
    });

    // Email validation
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (formData.email.length > 254) {
      errors.email = "Email is too long (maximum 254 characters)";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Please fill a valid email";
    }

    if (!formData.message || formData.message.length < 5) {
      errors.message = "Message is too short (minimum 5 characters)";
    } else if (formData.message.length > 1000) {
      errors.message = "Message is too long (maximum 1000 characters)";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleSubmit = async (isCloud) => {
    if (!validateForm()) {
      toast.error("Please fill all fields correctly");
      return;
    }

    if (isCloud) {
      ReactGA.event({
        category: "NewContactPage",
        action: "Contact form submit",
        label: "Submit contact form",
      });
    }

    setFormStatus({ message: "", isSubmitting: true });
    try {

        const response = await fetch(`${globalUrl}/api/v1/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstname: formData.firstName,
          lastname: formData.lastName,
          email: formData.email,
          title: formData.jobTitle,
          companyname: formData.companyName,
          category: categoryMap[formData.category],
          message: formData.message,
        }),
      });

      const data = await response.json();

      if (data.success) {
        toast.success("Thanks for submitting!", {
            duration: 1000,
        });
        if (formData.category === "book_a_demo") {
          setShowCalendly(true);
        } else {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            jobTitle: "",
            companyName: "",
            category: "",
            message: "",
          });
        }
      } else {
        throw new Error(data.reason || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message || "Please contact support@shuffler.io directly");
      setFormStatus((prev) => ({
        ...prev,
        message: error.message || "Failed to submit form please contact support@shuffler.io directly",
      }));
    } finally {
      setFormStatus((prev) => ({ ...prev, isSubmitting: false }));
    }
  };

  // Add URL parameter handling for category
  useEffect(() => {
    const categoryFromUrl = searchParams.get("category");
    if (categoryFromUrl && categoryMap[categoryFromUrl]) {
      const formattedCategory = { 
        key: categoryFromUrl, 
        value: categoryMap[categoryFromUrl] 
      };
      setFormData(prev => ({
        ...prev,
        category: formattedCategory.key
      }));
    }
  }, [searchParams]);

    // Scroll to top on mount
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [showCalendly]);

  // Add useEffect to load Calendly script and set up event listener
  React.useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('async', true);
    head.appendChild(script);

    // Add Calendly event listener
    window.addEventListener('message', (e) => {
      if (e.data.event === 'calendly.event_scheduled') {
        setShowThankYouMessage(true);
      }
    });

    return () => {
      head.removeChild(script);
      // Clean up event listener
      window.removeEventListener('message', (e) => {
        if (e.data.event === 'calendly.event_scheduled') {
          setShowThankYouMessage(true);
        }
      });
    };
  }, [showCalendly]);

  if (showCalendly) {
    return (
      <Box sx={{
        width: "100%",
        boxSizing: "border-box",
        textAlign: "center",
        backgroundColor: "#212121",
        borderRadius: "16px",
      }}>
        {!showThankYouMessage ? (
          <>
            <Typography variant="h6" sx={{ color: "#FFFFFF", mb: 1 }}>
              <Trans t={t}>Schedule a Demo here on your convenient time</Trans>
            </Typography>
            <div 
              className="calendly-inline-widget"
              data-url="https://calendly.com/sushant-shuffler/30min"
              style={{
                minWidth: "320px",
                height: "800px",
                border: "none",
                borderRadius: "8px",
                backgroundColor: "#212121",
                overflow: "hidden",
              }}
            />
            <Button
              sx={{
                mt: 2,
                mb: 2,
                backgroundColor: "#FF8544",
                color: "#ffffff",
                textTransform: "capitalize",
                fontSize: "16px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#FF955C",
                },
              }}
              variant="contained"
              onClick={() => setShowCalendly(false)}
            >
              <Trans t={t}>Back</Trans>
            </Button>
          </>
        ) : (
          <Box sx={{ 
            p: 4, 
            color: "#FFFFFF",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '400px',
            justifyContent: 'center',
          }}>
            <Grow in={true} timeout={1000}>
              <Box sx={{
                width: '64px',
                height: '64px',
                backgroundColor: '#FF8544',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 3,
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': {
                    transform: 'scale(1)',
                    boxShadow: '0 0 0 0 rgba(255, 133, 68, 0.4)',
                  },
                  '70%': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 0 0 10px rgba(255, 133, 68, 0)',
                  },
                  '100%': {
                    transform: 'scale(1)',
                    boxShadow: '0 0 0 0 rgba(255, 133, 68, 0)',
                  },
                },
              }}>
                <CheckIcon sx={{ 
                  fontSize: 32,
                  color: '#FFFFFF',
                  animation: 'fadeIn 1s ease-out',
                  '@keyframes fadeIn': {
                    '0%': {
                      opacity: 0,
                      transform: 'scale(0.5)',
                    },
                    '100%': {
                      opacity: 1,
                      transform: 'scale(1)',
                    },
                  },
                }} />
              </Box>
            </Grow>
            <Fade in={true} timeout={1000} style={{ transitionDelay: '500ms' }}>
              <Typography variant="h5" sx={{ 
                mb: 2,
                fontWeight: 600,
                fontSize: { xs: '20px', sm: '24px', md: '28px' },
                background: 'linear-gradient(90deg, #FF8544 0%, #FF955C 100%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                fontFamily: theme.typography.fontFamily,
                WebkitTextFillColor: 'transparent',
              }}>
                <Trans t={t}>Thank you for scheduling a demo!</Trans>
              </Typography>
            </Fade>
            <Fade in={true} timeout={1000} style={{ transitionDelay: '1000ms' }}>
              <Typography variant="body1" sx={{ 
                mb: 4,
                color: '#D8D8D8',
                maxWidth: '500px',
                lineHeight: 1.6,
                fontFamily: theme.typography.fontFamily,
                fontSize: { xs: '14px', sm: '16px' },
              }}>
                <Trans t={t}>
                  We're excited to show you how Shuffle can help streamline your workflow. 
                  You'll receive a calendar invitation shortly with all the details for our meeting.
                </Trans>
              </Typography>
            </Fade>
            <Fade in={true} timeout={1000} style={{ transitionDelay: '1500ms' }}>
              <Button
                sx={{
                  mt: 2,
                  px: 3,
                  py: 1.25,
                  backgroundColor: "#FF8544",
                  color: "#ffffff",
                  textTransform: "capitalize",
                  fontSize: { xs: '14px', sm: '16px' },
                  borderRadius: "8px",
                  fontFamily: theme.typography.fontFamily,
                  "&:hover": {
                    backgroundColor: "#FF955C",
                  },
                  transition: 'all 0.3s ease-in-out',
                }}
                variant="contained"
                onClick={() => {
                  setShowCalendly(false);
                  setShowThankYouMessage(false);
                  setFormData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    jobTitle: "",
                    companyName: "",
                    category: "",
                    message: "",
                  });
                }}
              >
                <Trans t={t}>Return to Contact Page</Trans>
              </Button>
            </Fade>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        maxWidth: "700px",
        gap: "32px",
        paddingBottom: "60px",
        fontFamily: theme.typography.fontFamily,
      }}
    >
      {/* Personal Info Section */}
      <Box sx={formStyles.formSection}>
        <Box sx={formStyles.flexRow}>
          <FormField
            label={<Trans t={t}>First Name</Trans>}
            placeholder={t("First name")}
            value={formData.firstName}
            onChange={handleInputChange("firstName")}
            error={formErrors.firstName}
          />
          <FormField
            label={<Trans t={t}>Last Name</Trans>}
            placeholder={t("Last name")}
            value={formData.lastName}
            onChange={handleInputChange("lastName")}
            error={formErrors.lastName}
          />
        </Box>
        <Box sx={formStyles.flexRow}>
          <FormField
            label={<Trans t={t}>Email Address</Trans>}
            placeholder={t("example@email.com")}
            value={formData.email}
            onChange={handleInputChange("email")}
            error={formErrors.email}
          />
          <FormField
            label={<Trans t={t}>Job Title</Trans>}
            placeholder={t("Enter your job title")}
            value={formData.jobTitle}
            onChange={handleInputChange("jobTitle")}
            error={formErrors.jobTitle}
          />
        </Box>
        <Box sx={formStyles.flexRow}>
          <FormField
            label={<Trans t={t}>Company Name</Trans>}
            placeholder={t("Enter your company name")}
            value={formData.companyName}
            onChange={handleInputChange("companyName")}
            error={formErrors.companyName}
          />
          <InputWrapper>
            <Label>{<Trans t={t}>Category</Trans>}</Label>
            <Autocomplete
              id="category-select"
              value={formData.category ? { 
                key: formData.category, 
                value: categoryMap[formData.category] 
              } : null}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#212121",
                  color: "white",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontFamily: theme.typography.fontFamily,
                  "& fieldset": {
                    border: `1px solid ${
                      formErrors.category
                        ? "#fd4c62"
                        : "rgba(255, 255, 255, 0.1)"
                    }`,
                    borderWidth: "1px",
                  },
                  "&:hover fieldset": {
                    borderColor: formErrors.category
                      ? "#fd4c62"
                      : "rgba(255, 255, 255, 0.3)",
                    borderWidth: "1px",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: formErrors.category
                      ? "#fd4c62"
                      : "rgba(255, 255, 255, 0.3)",
                    borderWidth: "1px",
                  },
                },
                "& .MuiAutocomplete-popupIndicator": {
                  color: "white",
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: "white",
                },
                "& .MuiAutocomplete-popper .MuiPaper-root": {
                  backgroundColor: "#212121",
                  color: "white",
                  borderRadius: "8px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                },
                "& .MuiAutocomplete-option": {
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                  '&[aria-selected="true"]': {
                    backgroundColor: "rgba(255, 255, 255, 0.15)",
                  },
                },
              }}
              options={Object.entries(categoryMap).map(([key, value]) => ({
                key,
                value
              }))}
              autoHighlight
              getOptionLabel={(option) => option?.value || ""}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              renderOption={(props, option) => (
                <Box component="li" {...props} sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "17px",
                  py: 1,
                }}>
                  {option.value}
                </Box>
              )}
              onChange={handleCategoryChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("Select a category")}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "white",
                      height: "19px",
                      "&::placeholder": {
                        color: "#c5c5c5",
                        fontWeight: "300",
                        fontSize: "16px",
                        opacity: 1,
                      },
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
            {formErrors.category && (
              <Typography
                sx={{
                  color: "#fd4c62",
                  fontSize: "12px",
                }}
              >
                {formErrors.category}
              </Typography>
            )}
          </InputWrapper>
        </Box>
      </Box>

      {/* Description Section */}
      <FormField
        label={<Trans t={t}>Describe your partnership plan</Trans>}
        placeholder={t("Anything we should know?")}
        multiline
        value={formData.message}
        onChange={handleInputChange("message")}
        error={formErrors.message}
      />

      {/* Submit Button */}
      <Button
        variant="contained"
        sx={formStyles.submitButton}
        onClick={() => handleSubmit(isCloud)}
        disabled={formStatus.isSubmitting}
        startIcon={
          formStatus.isSubmitting ? <CircularProgress size={20} /> : null
        }
      >
        <Trans t={t}>{formStatus.isSubmitting ? "Submitting..." : "Submit Form"}</Trans>
      </Button>

      {formStatus.message && (
        <Typography color="error" sx={{ textAlign: "center" }}>
          {formStatus.message}
        </Typography>
      )}
    </Box>
  );
};

const ContactUs = ({ globalUrl, serverside }) => {
  const { t } = useTranslation();
  const isCloud =
    serverside === true || typeof window === "undefined"
      ? true
      : window.location.host === "localhost:3002" ||
        window.location.host === "shuffler.io" ||
        window.location.host === "localhost:5002";

  const [formErrors, setFormErrors] = useState({});

  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // Add Drift chat functionality
  const openDriftChat = () => {
    if (window.drift) {
      window.drift.api.openChat();
    } else {
      console.error("Drift is not initialized.");
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 4, md: 8 }, pt: { xs: 3, md: 6 }, zoom: 1 }}>
      {/* Header Section */}
      <Box sx={{ textAlign: 'center', mb: 6, fontFamily: theme.typography.fontFamily }}>
        <Typography variant="h2" sx={{ 
          color: '#f1f1f1',
          fontSize: {
            xs: '28px',
            md: '36px',
            lg: '40px'
          },
          fontWeight: 600,
          mb: 1 
        }}>
          <Trans t={t}>Contact Us</Trans>
        </Typography>
        <Typography variant="body1" sx={{ 
          color: '#f1f1f1',
          fontSize: '16px' 
        }}>
          <Trans t={t}>Got any questions? We are here to help!</Trans>
        </Typography>
      </Box>

      {/* Main Content */}
      <Box sx={{
        display: 'flex',
        gap: { xs: 2, md: 4 },
        flexDirection: { xs: 'column-reverse', md: 'row' },
        backgroundColor: '#212121',
        borderRadius: '16px',
        // maxHeight: { 
        //   xs: 'none', 
        //   md: Object.keys(formErrors).length > 1 ? '790px' : showCalendly ? '790px' : '700px' 
        // },
        p: { xs: 2, md: 3 },
      }}>
        {/* Left Side - Contact Information */}
        <Box sx={{
          flex: '1',
          backgroundColor: '#2F2F2F',
          borderRadius: '16px',
          p: { xs: 3, md: 4 },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: { xs: 'auto', md: '500px' }
        }}>
          <Typography variant="h4" sx={{ 
            color: '#fff',
            fontSize: '32px',
            fontWeight: 500,
            mb: 3,
            fontFamily: theme.typography.fontFamily
          }}>
            <Trans t={t}>Contact<br />Information</Trans>
          </Typography>

          <Box>
            <Typography sx={{ 
              color: '#f1f1f1',
              mb: 2,
              fontFamily: theme.typography.fontFamily
            }}>
              <Trans t={t}>Other ways to contact us:</Trans>
            </Typography>
            
            {/* Contact Links */}
            <Stack spacing={2} alignItems="flex-start">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ChatBubbleOutlineIcon sx={{ color: '#f1f1f1' }} />
                <Link href="#"
                  onClick={() => {
                    openDriftChat();
                    if(isCloud) {
                      ReactGA.event({
                        category: 'NewContactPage',
                        action: 'LeftSidebar link clicked',
                        label: 'Shuffle Live Chat'
                      });
                    }
                  }}
                  sx={{ 
                    color: '#FF8444',
                    textDecoration: 'none',
                    '&:hover': { color: '#FF955C' },
                    fontSize: '16px',
                    fontFamily: theme.typography.fontFamily
                  }}>
                  <Trans t={t}>Shuffle Live Chat</Trans>
                </Link>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <MailOutlineIcon sx={{ color: '#f1f1f1' }} />
                <Link href="mailto:support@shuffler.io" 
                onClick={() => {
                  if(isCloud) {
                    ReactGA.event({
                      category: 'NewContactPage',
                      action: 'LeftSidebar link clicked',
                      label: 'Email to support@shuffler.io'
                    });
                  }
                }}
                sx={{ 
                  color: '#FF8444',
                  textDecoration: 'none',
                  fontSize: '16px',
                  '&:hover': { color: '#FF955C' },
                  fontFamily: theme.typography.fontFamily
                }}>
                  support@shuffler.io
                </Link>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <img src="/icons/discordWhite.svg" alt="Discord" />
                <Link href="https://discord.gg/B2CBzUm"
                onClick={() => {
                  if(isCloud) {
                    ReactGA.event({
                      category: 'NewContactPage',
                      action: 'LeftSidebar link clicked',
                      label: 'Join our Discord'
                    });
                  }
                }}
                sx={{ 
                  color: '#FF8444',
                  textDecoration: 'none',
                  '&:hover': { color: '#FF955C' },
                  fontSize: '16px',
                  fontFamily: theme.typography.fontFamily
                }}>
                  <Trans t={t}>Join our Discord</Trans>
                </Link>
              </Box>
            </Stack>
          </Box>
        </Box>

        {/* Right Side - Form */}
        <Box sx={{ 
          flex: 2, 
          pt: { xs: 2, md: 3 },
          width: '100%'
        }}>
          <ContactForm globalUrl={globalUrl} isCloud={isCloud} formErrors={formErrors} setFormErrors={setFormErrors} />
        </Box>
      </Box>
    </Container>
  );
};

export default ContactUs;
